$primary-color: #d79ef0;
$secundary-color: #8dcaff;
$dark-gray: #3a3a3a;
$gray: #999999;
$light-gray: #e6e6e6;
$background: #f5f5f5;
$background-header: rgba(245, 245, 245, 0.9);
$background-popup: rgba(255, 255, 255, 0.4);
$white: #ffffff;
$green: #afd392;
$yellow: #e08917;
$red: #af3d23;

@import "Login";
@import "Headers";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    color: $dark-gray;
}
.carousel-root {
    outline: none;
    border-radius: 8px;
    overflow: hidden;
}

body {
    background: $background;
    font-size: 10px;
}

.form-group {
    margin: 20px 0;
    label {
        width: 100%;
        display: inline-block;
        font-weight: 500;
        font-size: 1.3em;
    }
    input {
        border: 2px solid $light-gray;
        border-radius: 8px;
        width: 100%;
        height: 45px;
        padding: 5px 15px;
    }
    &.checkbox {
        label {
            display: inline-block;
            font-weight: 500;
            width: auto;
            font-size: 1.3em;
            position: relative;
            bottom: 7px;
            margin-left: 10px;
        }
        input[type="checkbox"] {
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            border: 2px solid $light-gray;
            border-radius: 6px;
            width: 20px;
            height: 20px;
            padding: 0;
            &:checked {
                background: $light-gray;
                color: white;
            }
            &:after {
                content: "✔";
                color: white;
                font-weight: 600;
                font-size: 15px;
                position: relative;
                bottom: 5px;
                left: 2px;
            }
        }
    }
}

.button-container {
    button {
        &.primary {
            background: $primary-color;
            border: none;
            width: 100%;
            padding: 13px;
            border-radius: 8px;
            color: white;
            font-weight: 600;
        }
    }
}

button:focus,
input:focus {
    outline-style: none;
    box-shadow: none;
}

button,
a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    i {
        color: white;
        margin-right: 5px;
    }
    &.principal {
        border: none;
        background: $primary-color;
        color: white;
        font-weight: 600;
        padding: 10px 15px;
        border-radius: 8px;
        font-size: 14px;
    }
}

.img-circle {
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
    }
}

#todo {
    width: calc(100% - 80px);
    position: relative;
    left: 80px;
    padding: 20px 25px 25px;
    display: flex;
    margin-top: 60px;
    .aside-bar {
        width: 270px;
        height: auto;
        border-radius: 10px;
        background: $white;
        margin: 15px;
    }

    .content {
        margin: 10px auto 15px;
        width: calc(100% - 270px);
        max-width: 1100px;
        .content-head {
            display: flex;
            width: 100%;
            align-items: center;
            .title {
                flex-grow: 4;
                h1 {
                    font-size: 26px;
                    font-weight: 600;
                    
                }
                .route {
                    font-size: 12px;
                    font-weight: 600;
                    position: relative;
                    bottom: 3px;
                }
            }
            .group-input {
                padding: 3px 0 0;
                display: flex;
                align-items: center;
                label {
                    width: 50%;
                    display: block;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0px;
                    padding-right: 5px;
                    text-align: right;
                    &.center {
                        text-align: center;
                    }
                }
                input[type="time"] {
                    width: 60%;
                    border: 2px solid $light-gray;
                    border-radius: 8px;
                    padding: 7px 10px;
                    margin: 5px 0 0;
                }
                button{
                    width: 130px;
                    margin-left: 5px;
                    background: $secundary-color;
                    padding: 8px 4px;
                    font-weight: 600px;
                    color: white;
                    border: none;
                    border-radius: 7px;
                    &.red{
                        background: $red;
                    }
                }
            }
        }
        .content-body {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding-top: 20px;
            div[class^="flex"] {
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
                align-items: flex-start;
                &.flex-3 {
                    width: 25%;
                }
                &.flex-4 {
                    width: 33.33%;
                }
                &.flex-5 {
                    width: 41.66%;
                }
                &.flex-7 {
                    width: 58.33%;
                }
                &.flex-8 {
                    width: 66.66%;
                }
                &.flex-9 {
                    width: 75%;
                }
                &.flex-6 {
                    width: 50%;
                    &.margin {
                        &:first-child {
                            margin-right: 8px;
                        }
                        &:last-child {
                            margin-left: 8px;
                        }
                    }
                }
                &.flex-12{
                    width: 100%;
                }
                &.left {
                    padding: 0 15px 0 0;
                }
                &.right {
                    padding: 0 0 0 15px;
                }
                &.both {
                    padding: 0 15px;
                }
                &.column {
                    flex-direction: column;
                }

                .card {
                    background: $white;
                    width: 100%;
                    padding: 20px 15px;
                    border-radius: 8px;
                    margin: 0 0 30px;
                    color: $gray;
                    .card-title {
                        letter-spacing: 3px;
                        font-weight: 700;
                        color: $gray;
                        font-size: 12px;
                        border-bottom: 3px solid $background;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        padding-top: 5px;
                        display: flex;
                        position: relative;
                        button {
                            position: absolute;
                            top: -5px;
                            border: none;
                            padding: 5px 10px;
                            right: 0;
                            color: white;
                            font-weight: 600;
                            border-radius: 4px;
                            &.green {
                                background: $green;
                            }
                            &.primary {
                                background: $primary-color;
                            }
                            &.secundary {
                                background: $secundary-color;
                            }
                        }
                    }
                    .subtitle {
                        font-size: 13px;
                        font-weight: 600;
                        color: $gray;
                        margin-top: 10px;
                    }
                    button.add {
                        background: $green;
                        width: 35px;
                        border-radius: 50%;
                        height: 35px;
                        margin: 0 0 0 10px;
                        flex-basis: 60px;
                        border: none;
                        i {
                            position: relative;
                            left: 3px;
                        }
                    }
                    button.remove {
                        background: $red;
                        width: 20px;
                        border-radius: 50%;
                        height: 20px;
                        margin: 0 10px 0 0;
                        border: none;
                        float: left;
                        i {
                            position: relative;
                            left: 3px;
                        }
                    }

                    button.edit {
                        background: none;
                        width: 20px;
                        border-radius: 50%;
                        height: 20px;
                        margin: 0 10px 0 0;
                        border: none;
                        float: left;
                        i {
                            position: relative;
                            left: 3px;
                            color: $yellow;
                        }
                    }
                    button.view {
                        background: none;
                        width: 20px;
                        border-radius: 50%;
                        height: 20px;
                        margin: 0 10px 0 0;
                        border: none;
                        float: left;
                        i {
                            position: relative;
                            left: 3px;
                            color: $secundary-color;
                        }
                    }
                    .table-card {
                        width: 100%;
                        overflow: auto;
                        table {
                            width: 100%;
                            font-size: 13px;
                            text-align: left;
                            margin-top: 10px;
                            tr {
                                td {
                                    padding: 3px 4px;
                                }
                                th {
                                    font-weight: 600;
                                    padding: 3px 4px;
                                }
                            }
                            .btn-container {
                                width: 35px;
                            }
                            .btn-container2 {
                                width: 70px;
                            }
                        }
                    }
                    .table-report {
                        width: 100%;
                        overflow: auto;
                        table {
                            width: 100%;
                            font-size: 13px;
                            text-align: left;
                            margin-top: 10px;
                            border: none;
                            border-collapse: collapse;
                            thead{
                                th{
                                    background: $light-gray;
                                    border: none;
                                }
                                
                            }
                            tr {
                                td {
                                    padding: 8px 4px;
                                    border: none;
                                    text-align: center;
                                    border-bottom: 1px solid $light-gray;
                                }
                                th {
                                    font-weight: 600;
                                    padding: 10px 4px;
                                    border: none;
                                    text-align: center;
                                }
                                &:hover{
                                    background: $background;
                                }
                            }
                        }
                    }
                    .save-container {
                        display: flex;
                        justify-content: flex-end;
                        padding: 5px 0 0;
                        button {
                            background: $secundary-color;
                            padding: 8px 20px;
                            font-size: 13px;
                            border-radius: 8px;
                            border: none;
                            color: $white;
                            font-weight: 600;
                        }
                    }
                    .group-input {
                        padding: 7px 0;
                        display: flex;
                        align-items: center;
                        &.no-padding-bottom {
                            padding: 5px 0 0 0;
                        }
                        &.no-padding-top {
                            padding: 0 0 5px 0;
                        }
                        .subtitle {
                            letter-spacing: 0;
                        }
                        &.column {
                            flex-direction: column;
                            width: 100%;
                            label {
                                width: 100%;
                            }
                            .group-radio {
                                width: 100%;
                            }
                        }
                        &.checkbox {
                            label {
                                display: inline-block;
                                font-weight: 500;
                                width: auto;
                                font-size: 1.3em;
                                position: relative;
                                bottom: 0px;
                                margin-left: 10px;
                            }
                            input[type="checkbox"] {
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                -o-appearance: none;
                                border: 2px solid $light-gray;
                                border-radius: 6px;
                                width: 20px;
                                height: 20px;
                                padding: 0;
                                &:checked {
                                    background: $light-gray;
                                    color: white;
                                }
                                &:after {
                                    content: "✔";
                                    color: white;
                                    font-weight: 600;
                                    font-size: 15px;
                                    position: relative;
                                    bottom: 5px;
                                    left: 2px;
                                }
                            }
                            .save-container {
                                width: 50%;
                            }
                        }
                        label {
                            width: 50%;
                            display: block;
                            font-weight: 500;
                            font-size: 12px;
                            letter-spacing: 0px;
                            padding-right: 5px;
                            &.center {
                                text-align: center;
                            }
                        }
                        input[type="text"] {
                            width: 100%;
                            border: 2px solid $light-gray;
                            border-radius: 8px;
                            padding: 7px 10px;
                            margin: 5px 0 0;
                        }
                        input[type="time"] {
                            width: 100%;
                            border: 2px solid $light-gray;
                            border-radius: 8px;
                            padding: 7px 10px;
                            margin: 5px 0 0;
                        }
                        textarea {
                            width: 100%;
                            border: 2px solid $light-gray;
                            border-radius: 8px;
                            padding: 7px 10px;
                            margin: 5px 0 0;
                        }
                        input[type="number"] {
                            width: 100%;
                            border: 2px solid $light-gray;
                            border-radius: 8px;
                            padding: 7px 10px;
                            margin: 5px 0 0;
                        }
                        input[type="date"] {
                            width: 100%;
                            border: 2px solid $light-gray;
                            border-radius: 8px;
                            padding: 7px 10px;
                            margin: 5px 0 0;
                        }

                        .group-radio {
                            padding: 10px 0 0;
                            display: flex;
                            input[type="radio"] {
                                -webkit-appearance: none;
                                border: 2px solid $light-gray;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                            input[type="radio"]:checked {
                                border: 2px solid $light-gray;
                                background: $gray;
                            }
                            label {
                                letter-spacing: 0;
                                font-weight: 500;
                            }
                        }
                        select {
                            border-radius: 8px;
                            padding: 5px 10px;
                            border: 2px solid $light-gray;
                            &:focus {
                                outline: none;
                            }
                        }
                        &.col-2 {
                            display: flex;
                            flex-wrap: nowrap;
                            label {
                                width: 100%;
                                display: block;
                                font-weight: 500;
                                font-size: 12px;
                                letter-spacing: 0px;
                            }
                        }
                    }
                }
                .container {
                    display: flex;
                    width: 100%;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    div[class^="flex"] {
                        .card.left {
                            margin: 0 15px 30px 0;
                        }
                        .card.right {
                            margin: 0 0 30px 15px;
                        }
                    }
                }
            }
        }
        button.filters {
            display: none;
        }
    }
}

.card {
    .table-container {
        display: flex;
        table {
            &#basicrules {
                padding-left: 25px;
            }
            th:first-child {
                width: 70px;
                text-align: left;
            }
            th {
                font-size: 13px;
                font-weight: 600;
                color: $gray;
            }
            td {
                font-size: 12px;
                font-weight: 600;
                color: $gray;
            }
            .group-input {
                padding: 0 !important;
            }
        }
        .select {
            position: relative;
            width: 130px;
            .subtitle {
                position: relative;
                bottom: 10px;
            }
            .container {
                position: absolute;
                display: flex;
                flex-direction: column;
                height: calc(100% - 35px);
                overflow: auto;
                width: 80px;
                border: 2px solid $light-gray;
                border-radius: 8px;
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $light-gray;
                    border-radius: 8px;
                    width: 5px;
                }
                .option {
                    padding: 8px;
                    width: 100%;
                    font-weight: 600;
                    font-size: 12px;
                    color: $gray;
                    cursor: pointer;
                    &.selected {
                        background: $background;
                    }
                }
            }
        }
    }
}

#table_component {
    table {
        border-collapse: collapse;
        width: 100%;
        margin: 40px 0 20px;
        thead {
            background: $light-gray;
            margin-bottom: 20px;
            tr {
                &:hover {
                    background-color: $light-gray;
                }
            }
        }
        tbody {
            background: $white;
            border-radius: 8px;
            overflow: hidden;
            padding: 10px;
            tr:first-child {
                td {
                    &:first-child {
                        border-top-left-radius: 8px;
                    }
                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }
            tr:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 8px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }
        tbody:before {
            content: "-";
            display: block;
            line-height: 2em;
            color: transparent;
            background: $background;
        }
        tr {
            margin: 10px 0;
            transition-duration: 100ms;
            &:hover {
                background-color: $light-gray;
            }
            td {
                border: none;
                padding: 15px 5px;
                border-bottom: 2px solid $background;
                vertical-align: middle;
                font-size: 12px;
                i {
                    &.material-icons {
                        &.green {
                            color: $green;
                        }
                        &.red {
                            color: $red;
                        }
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    font-size: 1em;
                    font-weight: 600;
                    color: $gray;
                }
                .text-container {
                    float: left;
                }
                .image-container {
                    float: left;
                    width: 44px;
                    height: 44px;
                    border-radius: 22px;
                    overflow: hidden;
                    margin: 0 15px;
                    background: white;
                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                    }
                }
                button.ghost,
                a {
                    margin-right: 10px;
                    display: block;
                    float: left;
                    background: none;
                    padding: 0;
                    margin: 0;
                    height: auto;
                    color: $gray !important;
                    border: none;
                    .view {
                        color: $secundary-color;
                    }
                    .edit {
                        color: $yellow;
                    }
                    .delete {
                        color: $red;
                    }
                    .download {
                        color: $primary-color;
                    }
                }
                &.error {
                    vertical-align: middle;
                    text-align: center;
                }
                &:first-child {
                    padding-left: 25px;
                }
                &:last-child {
                    padding-right: 25px;
                }
            }
            th {
                padding: 25px 10px;
                text-align: left;
                border: none;
                font-size: 12px;
                font-weight: 600;
                &.btn-container {
                    width: 120px;
                }
            }
            &:first-child {
                th {
                    &:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        padding-left: 25px;
                    }
                    &:last-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        padding-right: 25px;
                    }
                }
            }
        }
    }
    .head_table {
        h3 {
            font-size: 35px;
            span {
                font-size: 0.6em;
            }
        }
        .btn-container {
            text-align: right;
            button {
                margin-left: 10px;
            }
        }
    }
}
#todo{
.aside-bar {
    padding: 30px 20px;
    align-self: flex-start;
    button.remove {
        background: $red;
        width: 20px;
        border-radius: 50%;
        height: 20px;
        margin: 0 10px 0 0;
        border: none;
        float: left;
        i {
            position: relative;
            left: 3px;
        }
    }

    button.edit {
        background: none;
        width: 20px;
        border-radius: 50%;
        height: 20px;
        margin: 0 10px 0 0;
        border: none;
        float: left;
        i {
            position: relative;
            left: 3px;
            color: $yellow;
        }
    }
    button.view {
        background: none;
        width: 20px;
        border-radius: 50%;
        height: 20px;
        margin: 0 10px 0 0;
        border: none;
        float: left;
        i {
            position: relative;
            left: 3px;
            color: $secundary-color;
        }
    }
    .table-card {
        width: 100%;
        overflow: auto;
        table {
            width: 100%;
            font-size: 13px;
            text-align: left;
            margin-top: 0px;
            tr {
                td {
                    padding: 3px 4px;
                }
                th {
                    font-weight: 600;
                    padding: 3px 4px;
                }
            }
            .btn-container {
                width: 35px;
            }
            .btn-container2 {
                width: 70px;
            }
        }
    }
    h3 {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 4px;
        color: $primary-color;
        margin-bottom: 10px;
        text-transform: uppercase;
        &.center {
            justify-content: center;
            width: 100%;
            text-align: center;
            &.margin-bottom {
                margin-bottom: 20px;
            }
        }
        &.body{
            margin: 20px 0 10px;
        }
        i {
            color: $primary-color;
            font-size: 18px;
            margin-right: 8px;
        }
    }
    h2{
        text-transform: uppercase;
        &.center{
            width: 100%;
            text-align: center;
            position: relative;
            bottom: 5px;
        }
    }
    h4{
        text-transform: uppercase;
        &.center{
            width: 100%;
            text-align: center;
            position: relative;
            bottom: 5px;
            font-size: 12px;
            margin: 25px 0 5px;
            letter-spacing: 1px;
        }
    }

    &.report-filter-bar {
        width: 350px;
        margin: auto;
        padding: 40px 40px;
        .header {
            margin-bottom: 25px;
        }
        .group-input {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-top: 0;
            align-items: center;
            label {
                letter-spacing: 0px;
                width: auto;
                padding: 0 5px;
                color: $dark-gray;
                font-weight: normal;
            }
            label:first-child {
                padding-left: 0;
            }
        }
        .subtitle {
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 12px;
            color: $gray;
            font-weight: 600;
            margin-top: 5px;
        }
    }
    &.report-filter-bar-normal-width {
        .header {
            margin-bottom: 25px;
        }
        .group-input {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-top: 0;
            align-items: center;
            &.column{
                flex-direction: column;
                label{
                    text-align: right;
                    justify-content: start;
                    align-items: flex-end;
                }
            }
            label {
                letter-spacing: 0px;
                width: auto;
                padding: 0 5px;
                color: $dark-gray;
                font-weight: normal;

            }
            label:first-child {
                padding-left: 0;
            }
        }
        .subtitle {
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 12px;
            color: $gray;
            font-weight: 600;
            margin-top: 5px;
        }
        .item-filtered{
            font-size: 13px;
            color: $dark-gray;
            font-weight: 600;
            margin-top: 5px;
            width: 100%;
            text-align: left;
        }
    }
    .btn-container {
        display: flex;
        justify-content: flex-end;
        padding: 7px 0 0;
        button.principal {
            background: $secundary-color;
            padding: 8px 20px;
            font-size: 12px;
        }
    }
    .filters.btn-container {
        flex-direction: column;
        button {
            padding: 20px 30px 20px 15px;
            margin-bottom: 10px;
            justify-content: left;
            border: none;
            border-radius: 8px;
            background: $light-gray;
            font-weight: 600;
            position: relative;
            text-align: left;
            i {
                position: absolute;
                right: 5px;
                color: $gray;
            }
            &.active {
                background: $gray;
            }
        }
    }
    .col-2 {
        display: flex;
        .group-input {
            width: 50%;
        }
        div:first-child {
            padding-right: 5px;
        }
        div:last-child {
            padding-left: 5px;
        }
        button{
            border: none;
            padding: 5px;
            border-radius: 7px;
            color: white;
            font-weight: 600;
            &.green{
                background: $green;
            }
        }
    }
    button#close_popup {
        display: none;
    }
    .header {
        .btn-container {
            display: block;
            overflow: hidden;
            margin-bottom: 20px;
            .left {
                float: left;
            }
            .right {
                float: right;
            }
            button {
                background: none;
                border: none;
                color: $gray;
                font-weight: 600;
                i {
                    color: $gray;
                    font-size: 16px;
                }
            }
        }
    }
    .img-container {
        width: 100%;
        .circle {
            width: 160px;
            height: 160px;
            background: $light-gray;
            border-radius: 50%;
            margin: auto;
            position: relative;
            cursor: pointer;
            & > i {
                text-align: center;
                width: 100%;
                line-height: 160px;
                font-size: 60px;
                color: $gray;
            }
            button {
                position: absolute;
                bottom: 5px;
                right: 5px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: none;
                padding-left: 5px;
                background: $primary-color;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }
    &.info {
        padding: 20px 20px 30px;
        .subtitle {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 4px;
            color: $primary-color;
            margin: 10px 0;
        }
        .safe-zone{
            margin: 15px 0;
            padding-top: 10px;
            border-top: 2px solid $light-gray;
        }
        .group-input {
            padding: 4px 0;
            &.checkbox {
                label {
                    display: inline-block;
                    font-weight: 500;
                    width: auto;
                    font-size: 1.3em;
                    position: relative;
                    bottom: 0px;
                    margin-left: 10px;
                    color: $dark-gray;
                }
                input[type="checkbox"] {
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    border: 2px solid $light-gray;
                    border-radius: 6px;
                    width: 20px;
                    height: 20px;
                    padding: 0;
                    &:checked {
                        background: $light-gray;
                        color: white;
                    }
                    &:after {
                        content: "✔";
                        color: white;
                        font-weight: 600;
                        font-size: 15px;
                        position: relative;
                        bottom: 5px;
                        left: 2px;
                    }
                }
                .save-container {
                    width: 50%;
                }
            }
            label {
                width: 100%;
                display: block;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0;
                color: $gray;
            }
            input[type="text"],
            input[type="date"],
            select
             {
                -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                width: 100%;
                border: none!important;
                /*border-bottom: 2px solid $light-gray;*/
                border-radius: 0px;
                padding: 0px 0px 1px!important;
                margin: 0px 0 0;
                font-weight: $dark-gray;
                font-weight: 500;
                background: none !important;
                &.safe-zone{
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    width: 100%;
                    border: 2px solid $light-gray!important;
                    border-radius: 8px;
                    padding: 7px 10px!important;
                    margin: 5px 0 0;
                }
            }
            
            
            .group-radio {
                padding: 10px 0 0;
                display: flex;
                input[type="radio"] {
                    -webkit-appearance: none;
                    border: 2px solid $light-gray;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                input[type="radio"]:checked {
                    border: 2px solid $light-gray;
                    background: $gray;
                }
                label {
                    letter-spacing: 0;
                    font-weight: 500;
                }
            }
            select {
                border-radius: 8px;
                padding: 5px 10px;
                border: 2px solid $light-gray;
                &:focus {
                    outline: none;
                }
            }
            &.col-2 {
                display: flex;
                flex-wrap: nowrap;
                label {
                    width: 100%;
                    display: block;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0px;
                }
            }
        }
        &.create {
            input[type="text"],
            input[type="number"],
            input[type="date"],
            select {
                width: 100%;
                border: 2px solid $light-gray!important;
                border-radius: 8px;
                padding: 7px 10px!important;
                margin: 5px 0 0;
            }
        }
    }
}}
.dissapear {
    display: none !important;
}
.group-input {
    padding: 10px 0;
    width: 100%;
    &.col-2 {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        input[type="text"],
        select,
        input[type="number"],
        input[type="date"],
        input[type="search"],
        input[type="email"],
        input[type="password"] {
            width: 100%;
            border: 2px solid $light-gray;
            border-radius: 8px;
            padding: 7px 10px;
            margin: 5px 0 0;
        }
        button{
            margin-left: 5px;

        }
        label {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0px;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    &.no-padding-bottom {
        padding: 10px 0 0 0;
    }
    &.no-padding-top {
        padding: 0 0 10px 0;
    }
    label,
    .subtitle {
        width: 100%;
        display: block;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 2px;
        color: $gray;
    }

    input[type="text"],
    select,
    input[type="number"],
    input[type="date"],
    input[type="search"],
    textarea,
    input[type="email"],
    input[type="password"]  {
        width: 100%;
        border: 2px solid $light-gray;
        border-radius: 8px;
        padding: 7px 10px;
        margin: 5px 0 0;
    }
    .group-radio {
        padding: 10px 0 0;
        display: flex;
        input[type="radio"] {
            -webkit-appearance: none;
            border: 2px solid $light-gray;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 10px;
        }
        input[type="radio"]:checked {
            border: 2px solid $primary-color;
            background: $primary-color;
        }
        label {
            display: inline-block;
            font-weight: 500;
            width: auto;
            font-size: 1.3em;
            position: relative;
            letter-spacing: 0px;
            color: $dark-gray;
        }
    }
    .group-check {
        label {
            display: inline-block;
            font-weight: 500;
            width: auto;
            font-size: 1.3em;
            position: relative;
            bottom: 7px;
            margin-left: 10px;
            letter-spacing: 0px;
            padding: 10px 0 0;
            color: $dark-gray;
        }
        input[type="checkbox"] {
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            border: 2px solid $light-gray;
            border-radius: 6px;
            width: 20px;
            height: 20px;
            padding: 0;
            &:checked {
                background: $primary-color;
                border: 2px solid $primary-color;
                color: white;
            }
            &:after {
                content: "✔";
                color: white;
                font-weight: 600;
                font-size: 15px;
                position: relative;
                bottom: 5px;
                left: 2px;
            }
        }
    }
    select {
        border-radius: 8px;
        padding: 5px 10px;
        border: 2px solid $light-gray;
        &:focus {
            outline: none;
        }
    }

    &.col-2 {
        display: flex;
        flex-wrap: nowrap;

        label {
            width: 100%;
            display: block;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0px;
        }
        span,
        p {
            font-size: 12px;
            padding: 15px 0 0 10px;
        }
        .group-input {
            padding: 0;
            &:first-child {
                padding-right: 10px;
            }
            label {
                width: 100%;
                display: block;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 2px;
            }
        }
    }
    table.input {
        .group-input {
            padding: 0;
        }
        td {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
        }
    }
}

#pagination {
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $background;
    padding: 10px 0 5px;
    align-items: center;
    p {
        font-weight: 600;
        color: $dark-gray;
        font-size: 14px;
        margin-top: 10px;
        span {
            font-size: 15px;
            margin-left: 8px;
            color: $secundary-color;
        }
    }
    .buttons {
        flex-grow: 4;
        .btn-container {
            display: flex;
            justify-content: flex-end;
            button {
                background: $light-gray;
                align-items: center;
                margin: 0 5px;
                border: none;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-weight: 600;
                &.active {
                    background: $primary-color;
                    color: white;
                }
                &.ghost {
                    background: none;
                    &.active {
                        color: $primary-color;
                    }
                }
            }
            span {
                padding: 10px 0;
            }
        }
    }
    .qty-select {
        flex-grow: 1;
        label {
            color: $dark-gray;
            font-size: 14px;
            margin-top: 5px;
            flex: 0 0 80px;
            font-weight: 600;
        }
    }
    .results-qty {
        flex-grow: 1;
    }
}

.check-week {
    table {
        .principal {
            font-weight: 600;
            color: $gray;
            font-size: 12px;
        }
        td {
            padding: 3px 5px 3px 0;
            .group-input {
                padding: 0 !important;
            }
        }
    }
}
.no-chat-selected {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2,
    span {
        color: $gray;
    }



    h2 {
        font-size: 20px;
        text-align: center;
    }
    span {
        transform: rotate(180deg);
        font-size: 100px;
    }
}
.week-picker {
    position: absolute;
    background-color: white;
    right: 0;
    z-index: 1;
    top: 70px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    * {
        outline: none;
    }
    .DayPicker-NavBar {
        display: none;
    }
    .DayPicker-Caption {
        text-align: center;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $primary-color;
    }

    .DayPicker-Day--hoverRange:hover {
        background-color: $primary-color !important;
        color: $white;
    }

    .DayPicker-Day--selected {
        border-radius: 0;
        &:nth-child(2) {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        &:nth-child(6) {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }

    .apply {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        button {
            background-color: $secundary-color;
            padding: 5px 15px;
            color: white;
            border-radius: 8px;
            outline: none;
            border: none;
            font-weight: 600;
        }
    }
}

.chats-panel {
    overflow-y: auto;
    .container {
        height: 70vh !important;
    }
    .chat-actions {
        margin-bottom: 10px;
        button {
            width: 100%;
            margin-bottom: 20px;
            border-radius: 5px;
            font-weight: 600;
            display: block;

            font-size: 12px;
            padding: 10px;
            outline: none;
            border: none;

            background-color: $secundary-color;
            color: white;
            span {
                vertical-align: middle;
                color: white;
                margin-right: 10px;
            }
        }
    }
}

.chats {
    height: 100%;
    .chat {
        background-color: $light-gray;
        cursor: pointer;
        padding: 10px;
        width: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
        &-msg {
            font-weight: 600;
        }
        &-header {
            display: flex;

            margin-bottom: 5px;

            span {
                margin-left: auto;
            }

            &-info {
                font-weight: 700;
            }

            &-img {
                width: 35px;
                height: 35px;
                border-radius: 35px;
                overflow: hidden;
                margin-right: 10px;
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.mobile-chat-preview {
    display: none;
}

.selected-message {
    background-color: $gray !important;
    p {
        color: white !important;
    }
}

.messages-panel {
    margin-top: 10px;
    border-radius: 10px;
    height: 80vh;
    width: 100%;
    background-color: white;

    padding: 20px;
    position: relative;

    .msg-wrapper {
        height: 80%;
        display: flex;
        //justify-content: flex-end;
        flex-direction: column;
        overflow-y: auto;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    .current-chat-msg {
        margin-bottom: 40px;

        display: flex;

        &.sender {
            justify-content: flex-end;
            .current-chat-msg-img {
                order: 1;
                margin-right: 0;
                margin-left: 10px;
            }
        }

        .current-chat-wrapper {
            width: 60%;
            display: flex;
        }
        &-img {
            width: 40px;
            height: 40px;
            border-radius: 30px;
            overflow: hidden;
            margin-right: 10px;
            flex: none;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-content {
            font-size: 13px;
            padding: 15px;
            border-radius: 8px;
            background-color: $light-gray;
            margin-bottom: 5px;
        }
        &-date {
            font-weight: 600;
        }
    }

    .messages-text-box {
        height: 20%;
        width: calc(100% - 40px);
        background-color: $light-gray;
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 20px;
        .attached-file {
            position: absolute;
            bottom: 10px;
            background-color: $light-gray;
            font-size: 12px;
            font-weight: 600;
            padding: 3px;
        }
        textarea {
            height: 70px;
            width: 95%;
            resize: none;
            background-color: transparent;
            border: none;
            outline: none;
        }
        &-buttons {
            button {
                padding: 5px;
                border-radius: 5px;
                font-weight: 600;
                width: 100%;
                font-size: 11px;

                outline: none;
                border: none;
                span {
                    color: white;
                }
                &:first-child {
                    margin-bottom: 10px;
                }
                &.btn-file {
                    background-color: $secundary-color;
                }
                &.btn-send {
                    background-color: $primary-color;
                }
            }
        }
    }
}

.activity-panel {
    .principal {
        width: 100%;
        margin-bottom: 20px;
        background-color: $green;
    }
}
.circle-green {
    background-color: $green;
}

.caretaker-items {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    span {
        margin-right: 5px;
    }
    .view {
        color: $secundary-color;
    }
}

.card-vertical {
    .group-input {
        display: block !important;
    }
}

.waiting-list-btn {
    margin: 10px 0;
    button {
        border-radius: 5px;
        width: 50%;
        font-size: 11px;
        padding: 5px;

        outline: none;
        border: none;
        font-weight: 600;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &.red {
            background-color: $red;
            color: white;
        }
        &.yellow {
            background-color: $yellow;
            color: white;
        }
        &.primary {
            background-color: $primary-color;
            color: white;
        }
        &.secundary {
            background-color: $secundary-color;
            color: white;
        }
    }

    &-block {
        button {
            border-radius: 5px;
            font-weight: 600;
            display: block;
            width: 100%;
            font-size: 11px;
            padding: 10px 5px;
            outline: none;
            border: none;
            &.green {
                background-color: $green;
                color: white;
            }
        }
    }
}

.circle-blue {
    background-color: $secundary-color;
}

.circle-red {
    background-color: $red;
}

.circle-empty {
    background-color: $gray;
}

.rich-editor {
    border: 2px solid $light-gray;
    border-radius: 10px;
    //overflow: hidden;
}

.editor-right {
    height: 65vh;
    .card {
        height: 100%;
    }
}
.editor-left {
    .editor-variables {
        margin-top: 20px;
        p {
            font-size: 12px;
            font-weight: 600;
            color: $gray;
        }
        button {
            padding: 5px 20px;
            background-color: $green;
            color: white;
            border: none;
            outline: none;
            border-radius: 5px;
            font-weight: 600;
            font-size: 12px;
        }
    }
}

.rich-editor .rdw-editor-toolbar {
    border: none;
}

.rich-editor .rdw-editor-main {
    background-color: #fff;
}

.rich-editor .DraftEditor-root {
    padding: 10px;
    min-height: 250px;
}

.draft-editor-wrapper {
    border: 1px solid #ccc;
}

.param {
    margin-bottom: 10px;
    p {
        font-size: 12px;
        font-weight: 600;
    }
}

.shift-planning {
    .col-2 {
        justify-content: space-between;
        margin-bottom: 12px;
        p {
            font-size: 11px;
            font-weight: 600;
        }
    }
}

.row-select {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
        font-weight: 600;
        font-size: 13px;
        color: $gray;
    }
    label {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0px;
        padding-right: 5px;
        color: $gray;
    }
    select {
        border: 2px solid #e6e6e6;
        border-radius: 8px;
        padding: 0 10px;
        margin: 5px 0 0;
    }
    .select-input-wrapper {
        display: flex;
        .select-input {
            &:first-child {
                margin-right: 20px;
            }
        }
    }
}

.user-check {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 11px;
    .lvl-2 {
        margin-left: 10px;
    }
    .lvl-3 {
        margin-left: 25px;
    }
    input {
        margin-right: 10px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        border: 2px solid $light-gray;
        border-radius: 6px;
        width: 20px;
        height: 20px;
        padding: 0;
        &:checked {
            background: $light-gray;
            color: white;
        }
        &:after {
            content: "✔";
            color: white;
            font-weight: 600;
            font-size: 15px;
            position: relative;
            bottom: 5px;
            left: 2px;
        }
    }
}

.teachers {
    .available-teacher {
        width: 100%;
        background-color: $background;
        margin-bottom: 10px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        &-img {
            width: 35px;
            height: 35px;
            margin-right: 20px;
            border-radius: 35px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        &-info {
            text-align: center;
            font-weight: 600;
            .name {
                font-size: 12px;
            }
        }

        &.teacher-selected {
            background-color: $light-gray;
        }
    }
}
.teacher-add {
    background-color: $green;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-left: 10px;
    i {
        margin: 0;
    }
}

.shift-btn {
    padding: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    &:first-child {
        margin-right: 10px;
    }
    &.shift-green {
        background-color: $green;
    }
    &.shift-red {
        background-color: $red;
    }
}

.preview-container {
    background-color: $background;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 150px;
        margin-bottom: 20px;
    }
    .card {
        margin: 0 !important;
    }
}

.preview-content {
    word-wrap: break-word;
}

.html-view {
    background-color: #fff;
    color: #000;
    margin: auto;
    width: 70%;
    margin-bottom: 2rem;
}

.activity-info-panel {
    margin: 10px 0;
    text-align: center;
    h3 {
        display: block;
        margin: 0;
    }

    &-btn {
        margin-top: 10px;
        button {
            border-radius: 5px;
            font-weight: 600;
            display: block;
            width: 100%;
            font-size: 11px;
            padding: 10px;
            outline: none;
            border: none;
            &.green {
                background-color: $green;
                color: white;
            }
        }
    }
}

.aside-bar-segments {
    h2 {
        margin-bottom: 10px;
        text-align: center;
    }
    .aside-bar-segment {
        background-color: $background;
        margin-bottom: 10px;
        padding: 5px 10px;
        border-radius: 8px;
        display: flex;
        &-info {
            color: white;
            height: fit-content;
            text-align: center;
            padding: 10px 5px;
            margin-right: 10px;
            border-radius: 8px;
            p {
                color: white;
                font-weight: 600;
            }
            &.green {
                background-color: $green;
            }
            &.red {
                background-color: $red;
            }
            &.empty {
                background-color: $gray;
            }
        }
        &-items {
            .aside-bar-segment-item {
                background-color: white;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                .segment-item-img {
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                span {
                    font-weight: 500;
                    font-size: 9px;
                }
                p {
                    font-size: 11px;
                    font-weight: 700;
                }
            }
        }
    }
}

.teacher-card {
    display: block !important;
    .card {
        background: $white;
        width: 100%;
        padding: 20px 15px;
        border-radius: 8px;
        margin: 0 0 30px;
        color: $gray;
        .card-title {
            letter-spacing: 3px;
            font-weight: 700;
            color: $gray;
            font-size: 12px;
            border-bottom: 3px solid $background;
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
        .subtitle {
            font-size: 13px;
            font-weight: 600;
            color: $gray;
            margin-top: 10px;
        }
        button.add {
            background: $green;
            width: 35px;
            border-radius: 50%;
            height: 35px;
            margin: 0 0 0 10px;
            flex-basis: 60px;
            border: none;
            i {
                position: relative;
                left: 3px;
            }
        }
        button.remove {
            background: $red;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            margin: 0 10px 0 0;
            border: none;
            float: left;
            i {
                position: relative;
                left: 3px;
            }
        }
        button.edit {
            background: none;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            margin: 0 10px 0 0;
            border: none;
            float: left;
            i {
                position: relative;
                left: 3px;
                color: $yellow;
            }
        }
        .table-card {
            width: 100%;
            table {
                width: 100%;
                font-size: 13px;
                text-align: left;
                margin-top: 10px;
                tr {
                    td {
                        padding: 3px 4px;
                    }
                    th {
                        font-weight: 600;
                        padding: 3px 4px;
                    }
                }
                .btn-container {
                    width: 35px;
                }
                .btn-container2 {
                    width: 70px;
                }
            }
        }
        
        .save-container {
            display: flex;
            justify-content: flex-end;
            padding: 5px 0 0;
            button {
                background: $secundary-color;
                padding: 8px 20px;
                font-size: 13px;
                border-radius: 8px;
                border: none;
                color: $white;
                font-weight: 600;
            }
        }
        .group-input {
            padding: 7px 0;
            display: flex;
            align-items: center;
            &.no-padding-bottom {
                padding: 5px 0 0 0;
            }
            &.no-padding-top {
                padding: 0 0 5px 0;
            }
            .subtitle {
                letter-spacing: 0;
            }
            &.column {
                flex-direction: column;
                width: 100%;
                label {
                    width: 100%;
                }
                .group-radio {
                    width: 100%;
                }
            }
            &.checkbox {
                label {
                    display: inline-block;
                    font-weight: 500;
                    width: auto;
                    font-size: 1.3em;
                    position: relative;
                    bottom: 0px;
                    margin-left: 10px;
                }
                input[type="checkbox"] {
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    border: 2px solid $light-gray;
                    border-radius: 6px;
                    width: 20px;
                    height: 20px;
                    padding: 0;
                    &:checked {
                        background: $light-gray;
                        color: white;
                    }
                    &:after {
                        content: "✔";
                        color: white;
                        font-weight: 600;
                        font-size: 15px;
                        position: relative;
                        bottom: 5px;
                        left: 2px;
                    }
                }
                .save-container {
                    width: 50%;
                }
            }
            label {
                width: 50%;
                display: block;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0px;
                padding-right: 5px;
                &.center {
                    text-align: center;
                }
            }
            input[type="text"] {
                width: 100%;
                border: 2px solid $light-gray;
                border-radius: 8px;
                padding: 7px 10px;
                margin: 5px 0 0;
            }
            input[type="number"] {
                width: 100%;
                border: 2px solid $light-gray;
                border-radius: 8px;
                padding: 7px 10px;
                margin: 5px 0 0;
            }
            input[type="date"] {
                width: 100%;
                border: 2px solid $light-gray;
                border-radius: 8px;
                padding: 7px 10px;
                margin: 5px 0 0;
            }

            .group-radio {
                padding: 10px 0 0;
                display: flex;
                input[type="radio"] {
                    -webkit-appearance: none;
                    border: 2px solid $light-gray;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                input[type="radio"]:checked {
                    border: 2px solid $light-gray;
                    background: $gray;
                }
                label {
                    letter-spacing: 0;
                    font-weight: 500;
                }
            }
            select {
                border-radius: 8px;
                padding: 5px 10px;
                border: 2px solid $light-gray;
                &:focus {
                    outline: none;
                }
            }
            &.col-2 {
                display: flex;
                flex-wrap: nowrap;
                label {
                    width: 100%;
                    display: block;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0px;
                }
            }
        }
    }
}

.message-options {
    display: flex;
    position: absolute;
    bottom: 25%;
    width: calc(100% - 40px);
    .col-2 {
        margin-right: 20px;
        label {
            width: fit-content;
            margin-right: 10px;
        }
    }
}

.file-input-hidden {
    display: none;
}
.file-input-button {
    background-color: $primary-color;
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    margin-top: 10px;
    border-radius: 10px;
    color: white;
    font-weight: 600;
}

.button-filter-outter {
    width: 20px;
    height: 20px;
    border: 2px solid $gray;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    .inner {
        width: 10px;
        height: 10px;
        background-color: $gray;
        border-radius: 10px;
    }
    .inner-hidden {
        display: none;
    }
}

.button-filters {
    width: 100%;
    margin-top: 20px;
    .button-filter {
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: $light-gray;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 20px 30px 20px 15px;
        cursor: pointer;
        &-outter {
            width: 20px;
            height: 20px;
            border: 2px solid $gray;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .inner {
                width: 10px;
                height: 10px;
                background-color: $gray;
                border-radius: 10px;
            }
            .inner-hidden {
                display: none;
            }
        }
        p {
            font-weight: 600;
            margin-left: 10px;
            font-size: 13px;
        }
        i {
            position: absolute;
            right: 5px;
            color: $gray;
        }
        &.selected-filter {
            background-color: $gray !important;
        }
        &.selected-filter-light {
            background-color: $light-gray;
        }
        &.with-image {
            padding: 10px 30px 10px 10px;
            img{
                height: 40px;
            }
        }
        &.red {
            background: $red;
            p, i{
                color: $white;
            }
        }
        &.green {
            background: $green;
            p, i{
                color: $white;
            }
        }
        &.questions {
            padding: 10px 30px 10px 10px;
            b{
                display: block;
                width: 100%;
                letter-spacing: 1px;
                text-transform: 2px;
                margin-left: 10px;
                text-transform: uppercase;
                font-size: 10px;
            }
        }
    }
    .button-filter-light {
        background-color: $background;
    }
    .wage-add {
        width: 100%;
        background-color: $primary-color;
        padding: 15px;
        border-radius: 8px;
        border: none;
        outline: none;
    }
}

.flex-12 {
    width: 100%;
}

.calendar-content {
    overflow-x: auto;
}

.calendar-width {
    min-width: 750px;
}
#calendar-component {
    .calendar-content-mobile-checklist {
        display: block;
        .mobile-week-footer{
            margin-top: 10px;
            .item{
                display: flex;
                margin-top: 5px;
                .circle{
                    width: 14px;
                    height: 14px;
                    background: $yellow;
                    border-radius: 7px;
                }
                p{
                    font-size: 13px;
                    color: $yellow;
                    font-weight: 600;
                    margin-left: 5px;
                }
                &.absent{
                    .circle{
                        background: $red;
                    }
                    p{
                        color: $red;
                    }
                }
            }
        }
        &-header {
            display: flex;
            justify-content: space-around;
            
            .mobile-week-day {
                p {
                    text-align: center;
                    font-size: 13px;
                    font-weight: 700;
                    color: $gray;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                    &:first-child {
                        color: black;
                    }
                }
                .selected-day {
                    background-color: $primary-color;
                    color: white;
                    border-radius: 30px;
                }
                .red {
                    color: $red;
                }
                .yellow {
                    color: $yellow;
                }

                .week-day-wrapper {
                    display: flex;
                    justify-content: space-evenly;
                    .week-day-circle {
                        height: 5px;
                        width: 5px;
                        border-radius: 5px;
                    }
                }
            }
        }
        &-segments {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .mobile-segment {
                display: flex;
                margin-bottom: 10px;
                background-color: #e6e6e6;
                padding: 20px;
                border-radius: 8px;
                font-size: 13px;
                font-weight: 700;
                width: calc(50% - 20px);
                margin: 0 10px 10px;
                justify-content: space-evenly;
                &-info {
                    padding: 10px 5px;
                    background-color: $green;
                    margin-right: 10px;
                    border-radius: 8px;
                    text-align: center;
                    height: fit-content;
                    width: 20%;
                    p {
                        color: white;
                    }
                    &.green {
                        background-color: $green;
                    }

                    &.blue {
                        background-color: $secundary-color;
                    }

                    &.red {
                        background-color: $red;
                    }

                    &.empty {
                        background-color: $gray;
                    }
                }
                &-items {
                    width: 80%;
                    .mobile-item {
                        background-color: white;
                        margin-bottom: 5px;
                        padding: 8px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        button.chat{
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 100%;
                            padding: 8px;
                            width: 40px;
                            border: none;
                            border-radius: 10px;
                            background: $gray;
                            svg{
                                fill: $white;
                            }
                        }
                        .shift-extra-data {
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            font-weight: 700;
                        }
                        .shift-info {
                            text-align: center;
                            padding: 5px;
                            font-weight: 600;
                            .shift {
                                display: flex;
                                align-items: center;
                                .info {
                                    &-name {
                                        font-size: 12px;
                                        font-weight: 700;
                                    }
                                }
                                .shift-img {
                                    width: 30px;
                                    margin-right: 10px;
                                    height: 30px;
                                    img {
                                        width: 100%;
                                        border-radius: 30px;
                                    }
                                }
                            }
                        }

                        .lunch-info {
                            text-align: center;
                            .lunch-time {
                                color: $primary-color;
                            }
                            .lunch-title {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                            .lunch-group,
                            .lunch-description {
                                font-size: 11px;
                                font-weight: 600;
                            }
                        }

                        .child-info {
                            width: calc(100% - 45px);
                            p{
                                text-align: left;
                            }
                        }
                        .info {
                            text-align: center;
                            .extra-data {
                                font-size: 10px;
                            }
                        }
                        .img {
                            width: 35px;
                            height: 35px;
                            margin-right: 10px;
                            img {
                                width: 100%;
                            }
                        }

                        .activity-info {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            p {
                                text-align: center;
                            }

                            .info {
                                margin-top: 10px;
                            }
                            .info,
                            .location {
                                margin-bottom: 10px;
                            }
                            .location {
                                font-weight: 600;
                                font-size: 11px;
                            }
                            .info {
                                .item-title {
                                    text-align: center;
                                    font-weight: bold;
                                    font-size: 14px;
                                }
                                .item-time {
                                    font-weight: 700;
                                    letter-spacing: 4px;
                                    color: #d79ef0;
                                }
                            }

                            .item-teacher-info {
                                display: flex;
                                align-items: center;
                                font-weight: 600;
                                font-size: 11px;
                                &-img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 30px;
                                    overflow: hidden;
                                    margin-right: 5px;
                                    img {
                                        object-fit: cover;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .mobile-item-center {
                        justify-content: center;
                    }
                }
            }
        }
    }
    .calendar-content-mobile {
        display: none;
    }
    .calendar-actions {
        .export {
            button {
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: 3px solid $gray;
                background-color: transparent;
                color: $gray;
                font-weight: 600;
            }
        }

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        .picker {
            display: flex;
            position: relative;
            &-range-selector,
            &-calendar-selector,
            &-calendar-filter {
                background-color: #e6e6e6;
                border-radius: 50px;
                padding: 10px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 5px;
            }
            &-calendar-filter {
                display: none;
                width: 50px;
                height: 50px;
            }
            &-calendar-selector {
                width: 50px;
                height: 50px;
            }
        }
        .title {
            // flex-basis: 25%;
            visibility: hidden;
            .route {
                font-size: 12px;
                font-weight: 600;
                position: relative;
                bottom: 3px;
            }
        }
    }

    .calendar-content {
        &-header {
            padding: 15px;
            background-color: #e6e6e6;
            display: flex;
            justify-content: space-between;
            .header-year-mobile {
                display: none;
            }
            .header-col {
                width: 25%;
                &:not(:last-child) {
                    margin-right: 20px;
                }
                p {
                    text-align: center;
                    font-weight: bold;
                    font-size: 12px;
                }
            }
        }
        .calendar-day {
            width: 25%;
            border-radius: 8px;
            overflow: hidden;
            background-color: white;
            height: 40px;
        }
        &-items {
            margin-top: 10px;
            .item-row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .month-day {
                    background-color: white;
                    width: 25%;
                    margin: 5px;
                    padding: 10px;
                    border-radius: 10px;
                    //height: 100px;
                    &.disappear {
                        background: transparent;
                    }
                    .current-month-day {
                        display: flex;
                        &-number {
                            font-size: 20px;
                            font-weight: 700;
                            margin-top: 10px;
                        }
                        &-segments {
                            margin-top: 10px;
                            margin-left: 20px;
                            width: 100%;
                        }
                        &-segment {
                            display: flex;
                            justify-content: space-between;
                            p {
                                font-size: 17px;
                                font-weight: 500;
                            }

                            margin-bottom: 5px;
                            padding: 5px;
                            border-radius: 8px;
                            &.green {
                                background-color: $green;
                                p {
                                    color: white !important;
                                }
                            }
                            &.red {
                                background-color: $red;
                                p {
                                    color: white !important;
                                }
                            }
                            &.gray {
                                background-color: $gray;
                                p {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }

                .item-col {
                    width: 25%;
                    border-radius: 8px;
                    overflow: hidden;
                    background-color: #e6e6e6;

                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                    .segment {
                        &-header {
                            background-color: $secundary-color;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px;
                            p {
                                font-weight: 600;
                                color: white;
                                font-size: 12px;
                            }
                            .segment-waiting-list {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                            }
                        }
                        &-center {
                            justify-content: center;
                        }
                        .green {
                            background-color: $green;
                        }

                        .blue {
                            background-color: $secundary-color;
                        }

                        .red {
                            background-color: $red;
                        }

                        .empty {
                            background-color: $gray;
                        }
                        &-content {
                            padding: 4px;
                            .selected-item {
                                background-color: $gray;
                                p,
                                span {
                                    color: white !important;
                                }
                                .shift-extra-data {
                                    color: white;
                                }
                            }
                            &-item {
                                background-color: white;
                                border-radius: 4px;
                                padding: 5px;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                background-color: white;
                            margin-bottom: 5px;
                            padding: 8px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            button.chat{
                                height: 100%;
                                padding: 8px;
                                width: 40px;
                                border: none;
                                border-radius: 10px;
                                background: $gray;
                                svg{
                                    fill: $white;
                                }
                            }
                                .leave-days {
                                    height: auto;
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    padding: 5px;
                                    .number {
                                        background-color: $light-gray;
                                        padding: 8px;
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 5px;
                                        font-weight: bold;
                                        font-size: 11px;
                                        text-align: center;
                                    }
                                    .info {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        font-weight: 600;
                                        text-align: center;
                                        &-img {
                                            margin-bottom: 5px;
                                            width: 35px;
                                            height: 35px;
                                            border-radius: 30px;
                                            overflow: hidden;
                                            img {
                                                width: 100%;
                                            }
                                        }
                                        &-name {
                                            font-size: 12px;
                                            font-weight: 700;
                                        }
                                    }
                                }

                                .shift-extra-data {
                                    position: absolute;
                                    top: 5px;
                                    right: 10px;
                                    font-weight: 700;
                                }
                                .shift-info {
                                    text-align: center;
                                    padding: 5px;
                                    font-weight: 600;
                                    .shift {
                                        display: flex;
                                        align-items: center;
                                        .info {
                                            &-name {
                                                font-size: 12px;
                                                font-weight: 700;
                                            }
                                        }
                                        .shift-img {
                                            width: 30px;
                                            margin-right: 10px;
                                            height: 30px;
                                            img {
                                                width: 100%;
                                                border-radius: 30px;
                                            }
                                        }
                                    }
                                }

                                .lunch-info {
                                    text-align: center;
                                    .lunch-time {
                                        color: $primary-color;
                                        font-weight: 700;
                                    }
                                    .lunch-title {
                                        font-size: 12px;
                                        margin-bottom: 5px;
                                        font-weight: 700;
                                    }
                                    .lunch-group,
                                    .lunch-description {
                                        font-size: 10px;
                                        font-weight: 600;
                                    }
                                }
                                &:not(:last-child) {
                                    margin-bottom: 4px;
                                }

                                &-image {
                                    width: 30px;
                                    img {
                                        width: 100%;
                                    }
                                }
                                .general-item {
                                    width: calc(100% - 30px);
                                    text-align: center;

                                    &-info {
                                        text-align: center;
                                        .name {
                                            font-size: 11px;
                                            font-weight: 600;
                                            text-align: left;
                                            padding: 10px 0 10px 10px;
                                        }
                                        .extra-data {
                                            font-size: 10px;
                                            font-weight: 600;
                                        }
                                        .activity-info {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            p {
                                                text-align: center;
                                            }

                                            .info {
                                                margin-top: 5px;
                                            }
                                            .info,
                                            .location {
                                                margin-bottom: 10px;
                                            }
                                            .info {
                                                .item-title {
                                                    text-align: center;
                                                    font-weight: bold;
                                                    font-size: 12px;
                                                }
                                                .item-time {
                                                    font-weight: 700;
                                                    letter-spacing: 4px;
                                                    color: #d79ef0;
                                                }
                                            }

                                            .item-teacher-info {
                                                display: flex;
                                                align-items: center;
                                                &-img {
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 30px;
                                                    overflow: hidden;
                                                    margin-right: 5px;
                                                    img {
                                                        object-fit: cover;
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }

                                        p {
                                            //margin-right: 5px;

                                            font-weight: 600;
                                        }
                                    }
                                    &-sub {
                                        text-align: center;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                        &-add {
                            background-color: rgb(211, 211, 211);
                            margin: 0 5px 5px 5px;
                            border-radius: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 5px;
                            cursor: pointer;
                            span {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.select-table {
    border: 2px solid $light-gray;
    margin-top: 15px;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    overflow: auto;
    table {
        border-collapse: collapse;
        width: 100%;
        thead {
            border-bottom: 2px solid $light-gray;
        }
        tr {
            cursor: pointer;
            td,
            th {
                padding: 5px 3px;
                font-size: 12px;
                text-align: center;
                font-weight: 600;
            }
            td {
                color: $gray;
                font-weight: 500;
                padding: 8px 3px;
            }
            &:first-child {
                td {
                    padding: 10px 0 5px;
                }
            }
            &.selected {
                background: $background;
                td:first-child {
                    border-radius: 4px 0 0 4px;
                }
                td:last-child {
                    border-radius: 0px 4px 4px 0px;
                }
            }
        }
        button.ghost {
            
            display: block;
            float: left;
            background: none;
            padding: 0;
            margin: 0;
            height: auto;
            color: $gray !important;
            border: none;
            .view {
                color: $secundary-color;
            }
            .edit {
                color: $yellow;
            }
            .delete {
                color: $red;
            }
            .download {
                margin-top: 5px;
                color: $primary-color;
            }
        }
    }
}

.btn-container.select-table-btn {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    width: 100%;
    button {
        padding: 8px 15px;
        margin: 0 0 0 5px;
        border-radius: 8px;
        border: none;
        color: white;
        font-weight: 600;
        &.green {
            background: $green;
        }

        &.secundary {
            background: $secundary-color;
        }
        &.red {
            background: $red;
        }
    }
}
.btn-container.invoice-select-table-btn {
    display: flex;
    padding: 10px 0 0;
    width: 100%;
    .generate-invoice{
        width: 30%;
    }
    .change-inputs{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        .group-input{
            width: 40%;
            margin-left: 20px;
        }
    }
    .group-input{
        label{
            width: auto!important;
        }
        input{
            width: auto!important;
        }
    }
    button {
        padding: 4px 5px;
        margin: 0 0 0 5px;
        border-radius: 8px;
        border: none;
        color: white;
        font-weight: 600;
        font-size: 12px;
        &.green {
            background: $green;
        }
        &.primary{
            background: $primary-color;
        }
        &.secundary {
            background: $secundary-color;
        }
        &.red {
            background: $red;
        }
    }
}


    .checklist{
        width: 100%;
        margin: 10px 0;
        background: $white;
        border-radius: 8px;
        overflow: hidden;
        transition-duration: 300ms;
        position: relative;
        .header{
            width: 100%;
            background: $light-gray;
            display: flex;
            padding: 15px 20px;
            font-size: 12px;
            font-weight: 600;
            border-radius: 8px;
            position: relative;
            z-index: 50;
            cursor: pointer;
            &.complete{
                background: $green;
                color: white;
                h3, i{
                    color: white;
                }
            }
            i{
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 35px;
            }
        }
        .card{
            padding: 10px 20px;
            transition-duration: 300ms;
            margin-bottom: 0px!important;
            &.hidden{
                position: absolute;
                bottom: 0;
            }
            .btn-container{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                button{
                    display: block;
                    background: $secundary-color;
                }
            }
            p{
                font-size: 12px;
                font-weight: 600;
            }
            .group-check{
                width: 100%;
                display: flex;
            }
        }
    }
#occupancy{
    padding-left: 20px;
    position: relative;
    border-bottom: 1px solid $gray;
    margin-bottom: 20px;
    .occupancy-chart{
        display: flex;
        height: 240px;
        width: 100%;
        margin-top: 100px;
        align-items: flex-end;
        .item{
            background: $gray;
            display: block;
            margin: 0 5px;
            border-radius: 6px 6px 0 0;
            color: white;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 50;

            &.red{
                background: $red;
            }
            &.green{
                background: $green;
            }
        }
    }
    .occupancy-footer{
        display: flex;
        width: calc(100% - 20px);
        position: absolute;
        left: 20px;
        top: 100%;
        margin-top: 5px;
        .item{
            text-align: center;
            font-weight: 600;
            color: $gray;
        }
    }
    .percent50{
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        border: 1px dashed $light-gray;
        bottom: 120px;
        &::before{
            content: "50%";
            position: absolute;
            right: 100%;
            bottom: -8px;
            font-weight: 600;
        }
    }
    .percent100{
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        border: 1px dashed $light-gray;
        bottom: 240px;
        &::before{
            content: "100%";
            position: absolute;
            right: 100%;
            bottom: -8px;
            font-weight: 600;
        }
    }
} 
.tab{
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    font-size: 13px;
    color: white;
    margin: 10px 0;
    b{
        color: white;
    }
    &.warning{
        background: $yellow;
    }
    &.error{
        background: $red;
    }
}
#home{
    width: calc(100% - 270px);
    max-width: 1100px;
    margin: auto;
    .content-body {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding-top: 20px;
        flex-direction: column;
        width: 100%;
        div[class^="flex"] {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            width: 100%;
            .slider{
                margin-top: 33px;
            }
            h1{
                font-size: 27px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            &.flex-3 {
                width: 25%;
            }
            &.flex-4 {
                width: 33.33%;
            }
            &.flex-5 {
                width: 41.66%;
            }
            &.flex-7 {
                width: 58.33%;
            }
            &.flex-8 {
                width: 66.66%;
            }
            &.flex-9 {
                width: 75%;
            }
            &.flex-12 {
                width: 100%;
            }
            &.flex-6 {
                width: 50%;
                &.margin {
                    &:first-child {
                        margin-right: 8px;
                    }
                    &:last-child {
                        margin-left: 8px;
                    }
                }
            }
            &.left {
                padding: 0 15px 0 0;
            }
            &.right {
                padding: 0 0 0 15px;
            }
            &.both {
                padding: 0 15px;
            }
            &.column {
                flex-direction: column;
            }
            h2{
                width: 100%;
                font-size: 22px;
                font-weight: 600;
                color: $gray;
                margin: 25px 0 15px;
                text-align: center;
            }
            .col-2{
                display: flex;
                width: 100%;
                .column{
                    width: 50%;
                    padding: 0 10px;
                    .header{
                        width: 100%;
                        padding: 10px 0;
                        background: $green;
                        border-radius: 7px;
                        text-align: center;
                        color: $white;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                    .item-col {
                        width: 100%;
                        border-radius: 8px;
                        overflow: hidden;
                        background-color: #e6e6e6;
                        
                        &:not(:last-child) {
                            margin-right: 20px;
                        }
                        .segment {
                            &-header {
                                background-color: $secundary-color;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 10px;
                                p {
                                    font-weight: 600;
                                    color: white;
                                    font-size: 12px;
                                }
                                .segment-waiting-list {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                }
                            }
                            &-center {
                                justify-content: center;
                            }
                            .green {
                                background-color: $secundary-color;
                            }
    
                            .blue {
                                background-color: $secundary-color;
                            }
    
                            .red {
                                background-color: $red;
                            }
    
                            .empty {
                                background-color: $gray;
                            }
                            &-content {
                                padding: 4px;
                                .selected-item {
                                    background-color: $gray;
                                    p,
                                    span {
                                        color: white !important;
                                    }
                                    .shift-extra-data {
                                        color: white;
                                    }
                                }
                                &-item {
                                    background-color: white;
                                    border-radius: 4px;
                                    padding: 5px;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: relative;
                                    background-color: white;
                                margin-bottom: 5px;
                                padding: 8px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                position: relative;
                                button.chat{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    height: 100%;
                                    padding: 8px;
                                    width: 40px;
                                    border: none;
                                    border-radius: 10px;
                                    background: $gray;
                                    svg{
                                        fill: $white;
                                    }
                                }
                                    .leave-days {
                                        height: auto;
                                        display: flex;
                                        justify-content: space-between;
                                        width: 100%;
                                        padding: 5px;
                                        .number {
                                            background-color: $light-gray;
                                            padding: 8px;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 5px;
                                            font-weight: bold;
                                            font-size: 11px;
                                            text-align: center;
                                        }
                                        .info {
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                            font-weight: 600;
                                            text-align: center;
                                            &-img {
                                                margin-bottom: 5px;
                                                width: 35px;
                                                height: 35px;
                                                border-radius: 30px;
                                                overflow: hidden;
                                                img {
                                                    width: 100%;
                                                }
                                            }
                                            &-name {
                                                font-size: 12px;
                                                font-weight: 700;
                                            }
                                        }
                                    }
    
                                    .shift-extra-data {
                                        position: absolute;
                                        top: 5px;
                                        right: 10px;
                                        font-weight: 700;
                                    }
                                    .shift-info {
                                        text-align: center;
                                        padding: 5px;
                                        font-weight: 600;
                                        .shift {
                                            display: flex;
                                            align-items: center;
                                            .info {
                                                &-name {
                                                    font-size: 12px;
                                                    font-weight: 700;
                                                }
                                            }
                                            .shift-img {
                                                width: 30px;
                                                margin-right: 10px;
                                                height: 30px;
                                                img {
                                                    width: 100%;
                                                    border-radius: 30px;
                                                }
                                            }
                                        }
                                    }
    
                                    .lunch-info {
                                        text-align: center;
                                        .lunch-time {
                                            color: $primary-color;
                                            font-weight: 700;
                                        }
                                        .lunch-title {
                                            font-size: 12px;
                                            margin-bottom: 5px;
                                            font-weight: 700;
                                        }
                                        .lunch-group,
                                        .lunch-description {
                                            font-size: 10px;
                                            font-weight: 600;
                                        }
                                    }
                                    &:not(:last-child) {
                                        margin-bottom: 4px;
                                    }
    
                                    &-image {
                                        width: 30px;
                                        img {
                                            width: 100%;
                                        }
                                    }
                                    .general-item {
                                        width: calc(100% - 30px);
                                        text-align: center;
    
                                        &-info {
                                            text-align: center;
                                            .name {
                                                font-size: 11px;
                                                font-weight: 600;
                                                text-align: left;
                                                padding: 10px;
                                            }
                                            .extra-data {
                                                font-size: 10px;
                                                font-weight: 600;
                                            }
                                            .activity-info {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                p {
                                                    text-align: center;
                                                }
    
                                                .info {
                                                    margin-top: 5px;
                                                }
                                                .info,
                                                .location {
                                                    margin-bottom: 10px;
                                                }
                                                .info {
                                                    .item-title {
                                                        text-align: center;
                                                        font-weight: bold;
                                                        font-size: 12px;
                                                    }
                                                    .item-time {
                                                        font-weight: 700;
                                                        letter-spacing: 4px;
                                                        color: #d79ef0;
                                                    }
                                                }
    
                                                .item-teacher-info {
                                                    display: flex;
                                                    align-items: center;
                                                    &-img {
                                                        width: 30px;
                                                        height: 30px;
                                                        border-radius: 30px;
                                                        overflow: hidden;
                                                        margin-right: 5px;
                                                        img {
                                                            object-fit: cover;
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                            }
    
                                            p {
                                                //margin-right: 5px;
    
                                                font-weight: 600;
                                            }
                                        }
                                        &-sub {
                                            text-align: center;
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                            &-add {
                                background-color: rgb(211, 211, 211);
                                margin: 0 5px 5px 5px;
                                border-radius: 4px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 5px;
                                cursor: pointer;
                                span {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
            .card {
                background: $white;
                width: 100%;
                padding: 20px 15px;
                border-radius: 8px;
                margin: 0 0 30px;
                color: $gray;
                .messages-container{
                    display: flex;
                    flex-direction: column;
                    .message{
                        background: $background;
                        padding: 15px 10px;
                        border-radius: 8px;
                        position: relative;
                        margin-bottom: 10px;
                        a{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            i{
                                font-size: 28px;
                                color: $gray;
                            }
                        }
                        .from{
                            font-weight: 600;
                            font-size: 12px;
                            margin-bottom: 5px;
                        }
                        .content-message{
                            font-size: 12px;
                        }
                    }
                }
                .btn-container{
                    display: flex;
                    button{
                        margin: 10px 5px;
                        padding: 15px 5px;
                        background: $background;
                        border: none;
                        border-radius: 8px;
                        font-weight: 600;
                        font-size: 12px;
                        color: $gray;
                        display: flex;
                        flex-direction: column;
                        svg{
                            width: 30px;
                            fill: $gray;
                            margin-bottom: 5px;
                        }
                    }
                }
                .table-home{
                    table{
                        border-collapse: collapse;
                        width: 100%;
                        thead {
                            th{
                                padding: 10px 0;
                                font-size: 12px;
                                text-align: left;
                                padding: 10px 10px 0;
                                font-weight: 600;
                             }
                        }
                        tr{
                            td{
                                .content-table{
                                    background: $background;
                                    margin-top: 10px;
                                    padding: 10px 10px;
                                    font-size: 12px;
                                    height: 55px;
                                    display: flex;
                                    align-items: center;
                                    i{
                                        &.green{
                                            color: $green;
                                        }
                                        &.red{
                                            color: $red;
                                        }
                                    }
                                }
                                &:first-child{
                                    .content-table{
                                        border-radius: 7px 0 0 7px;
                                    }
                                }
                                &:last-child{
                                    .content-table{
                                        border-radius:  0 7px 7px 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .save-button{
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    margin-top: 8px;
                    button{
                        background: $secundary-color;
                        border: none;
                        padding: 8px 12px;
                        color: white;
                        font-weight: 600;
                        border-radius: 8px;
                    }
                }
                .card-title {
                    letter-spacing: 3px;
                    font-weight: 700;
                    color: $gray;
                    font-size: 12px;
                    border-bottom: 3px solid $background;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    padding-top: 5px;
                    display: flex;
                    position: relative;
                    button {
                        position: absolute;
                        top: -5px;
                        border: none;
                        padding: 5px 10px;
                        right: 0;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                        &.green {
                            background: $green;
                        }
                        &.primary {
                            background: $primary-color;
                        }
                        &.secundary {
                            background: $secundary-color;
                        }
                        &.orange{
                            background: $yellow;
                        }
                    }
                }
                
            }
            
        }
    }
}

@media (max-width: 900px) {
    .no-chat-selected {
        span {
            transform: rotate(270deg);
            font-size: 100px;
        }
    }
    .btn-container.invoice-select-table-btn {
        display: flex;
        padding: 10px 0 0;
        width: 100%;
        flex-direction: column;
        .generate-invoice{
            width: 100%;
        }
        .change-inputs{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            .group-input{
                width: 100%;
                margin-left: 0px;
                button {
                    height: 45px;
                }
            }
        }
        
    }
    .dissapear-responsive{
        display: none;
    }
    #home{
        width: 100%;
        .content-body {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            padding-top: 20px;
            flex-direction: column;
            width: 100%;
            display: block;
                div[class^="flex"] {
                    display: block;
                    width: 100% !important;
                    .slider{
                        margin-top: 0;
                    }
                    &.column {
                        display: block;
                    }
                    &.left {
                        padding: 0;
                    }
                    &.right {
                        padding: 0;
                    }
                    &.both {
                        padding: 0;
                    }
                    .btn-container{
                        display: flex;
                        overflow: auto;
                        button{
                            flex-shrink: 0;
                            margin: 10px 5px;
                            padding: 15px 5px;
                            background: $background;
                            border: none;
                            border-radius: 8px;
                            font-weight: 600;
                            font-size: 12px;
                            color: $gray;
                            display: flex;
                            flex-direction: column;
                            svg{
                                width: 30px;
                                fill: $gray;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
                
            }
        }
    
    .children-checklist{
        display: flex;
        overflow-x: auto;
        margin: 10px 0;
        .button-filter{
            flex-shrink: 0;
            height: 100px;
            width: 100px;
            padding: 5px!important;
            border-radius: 10px;
            background-color: #e6e6e6;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 700;
            text-align: center;
            margin: 0 5px;
            i{
                display: none;
            }
        }
    }
    body {
        overflow-x: hidden;
    }
    #todo {
        width: 100%;
        left: 0px;
        padding: 10px 20px 150px;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        .aside-bar {
            height: auto;
            border-radius: 10px;
            background: $white;
            margin: 0px;
            width: 100%;
        }
        .aside-bar-info-active {
            max-height: 75vh;
            overflow-y: auto;
        }
        .content {
            margin: 0px;
            flex-grow: 5;
            width: 100%;
            .content-head {
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 30px;
                &.without-title{
                    .title{
                    display: none;

                    }
                }
                &.responsive{
                    flex-direction: column;
                }
                .title {
                    flex-grow: 4;
                    
                    h1 {
                        font-size: 26px;
                        font-weight: 600;
                    }
                    .route {
                        font-size: 12px;
                        font-weight: 600;
                        position: relative;
                        bottom: 3px;
                    }
                }
                button.filters {
                    display: block;
                    background: $gray;
                    border: none;
                    border-radius: 50%;
                    padding: 5px;
                    margin-right: 10px;
                    i {
                        position: relative;
                        top: 3px;
                        left: 3px;
                    }
                }
            }
            .content-body {
                display: block;
                div[class^="flex"] {
                    display: block;
                    width: 100% !important;
                    &.column {
                        display: block;
                    }
                    &.left {
                        padding: 0;
                    }
                    &.right {
                        padding: 0;
                    }
                    &.both {
                        padding: 0;
                    }

                    .card {
                        .save-container {
                            display: flex;
                            justify-content: flex-end;
                            padding: 5px 0 0;
                            button {
                                background: $secundary-color;
                                padding: 8px 20px;
                                font-size: 13px;
                                border-radius: 8px;
                                border: none;
                                color: $white;
                                font-weight: 600;
                            }
                        }
                        .group-input {
                            &.col-2 {
                                display: block;
                                flex-wrap: nowrap;
                                label {
                                    width: 100%;
                                    display: block;
                                    font-weight: 500;
                                    font-size: 12px;
                                    letter-spacing: 0px;
                                }
                            }
                        }
                    }
                    .container {
                        display: block;
                        div[class^="flex"] {
                            .card.left {
                                margin: 0;
                            }
                            .card.right {
                                margin: 0;
                            }
                        }
                    }
                }
            }
            button.filters {
                display: none;
            }
        }
    }
    #table_component {
        width: 100%;
        overflow-x: auto;
        table {
            border-collapse: collapse;
            margin: 0px 0 0px;
            thead {
                background: $light-gray;
                margin-bottom: 20px;
                tr {
                    &:hover {
                        background-color: $light-gray;
                    }
                }
            }
            tbody {
                background: $white;
                border-radius: 8px;
                overflow: hidden;
                padding: 10px;
                tr:first-child {
                    td {
                        &:first-child {
                            border-top-left-radius: 8px;
                        }
                        &:last-child {
                            border-top-right-radius: 8px;
                        }
                    }
                }
                tr:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 8px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
            }
            tbody:before {
                content: "-";
                display: block;
                line-height: 2em;
                color: transparent;
                background: $background;
            }
            tr {
                margin: 10px 0;
                transition-duration: 100ms;
                &:hover {
                    background-color: $light-gray;
                }
                td {
                    border: none;
                    padding: 15px 5px;
                    border-bottom: 2px solid $background;
                    vertical-align: middle;
                    font-size: 12px;
                    text-align: left;
                    span {
                        display: block;
                        width: 100%;
                        font-size: 1em;
                        font-weight: 600;
                        color: $gray;
                    }
                    .text-container {
                        float: left;
                    }
                    .image-container {
                        float: left;
                        width: 44px;
                        height: 44px;
                        border-radius: 22px;
                        overflow: hidden;
                        margin: 0 15px;
                        background: white;
                        img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                            margin: auto;
                        }
                    }
                    button.ghost {
                        margin-right: 10px;
                        display: block;
                        float: left;
                        background: none;
                        padding: 0;
                        margin: 0;
                        height: auto;
                        color: $gray !important;
                        border: none;
                        .view {
                            color: $secundary-color;
                        }
                        .edit {
                            color: $yellow;
                        }
                        .delete {
                            color: $red;
                        }
                        .download {
                            color: $primary-color;
                        }
                    }
                    &.error {
                        vertical-align: middle;
                        text-align: center;
                    }
                    &:first-child {
                        padding-left: 25px;
                    }
                    &:last-child {
                        padding-right: 25px;
                    }
                }
                th {
                    padding: 20px 10px;
                    text-align: left;
                    border: none;
                    font-size: 12px;
                    font-weight: 600;
                    &.btn-container {
                        width: auto;
                    }
                }
                &:first-child {
                    th {
                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            padding-left: 25px;
                        }
                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            padding-right: 25px;
                        }
                    }
                }
            }
        }
        .head_table {
            h3 {
                font-size: 35px;
                span {
                    font-size: 0.6em;
                }
            }
            .btn-container {
                text-align: right;
                button {
                    margin-left: 10px;
                }
            }
        }
    }
#todo{
    .aside-bar {
        padding: 30px 20px;
        align-self: flex-start;
        h3 {
            display: flex;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 4px;
            color: $primary-color;
            margin-bottom: 15px;
            i {
                color: $primary-color;
                font-size: 18px;
                margin-right: 8px;
            }
        }
        h2{
            .center{
                width: 100%;
                text-align: center;
            }
        }
        .btn-container {
            display: flex;
            justify-content: flex-end;
            padding: 7px 0 0;
            button.principal {
                background: $secundary-color;
                padding: 8px 20px;
                font-size: 12px;
            }
        }
        &.popup {
            background: $background-popup !important;
            position: fixed;
            width: 100vw;
            height: 100vh !important;
            top: 0;
            left: 0;
            z-index: 105;
            justify-content: center;
            align-items: center;
            display: none;
            .container {
                background: $white;
                padding: 30px 20px;
                border-radius: 8px;
                width: 300px !important;
                position: relative;
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
                button#close_popup {
                    display: block;
                    width: 35px;
                    height: 35px;
                    border-radius: 8px;
                    background: none;
                    border: none;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    i {
                        font-size: 30px;
                        color: $gray;
                    }
                }
            }
            &.active {
                display: flex;
            }
        }
        &.report-filter-bar {
            width: 100%;
            padding: 40px 30px;
        }
    }
}
    
    .group-input {
        padding: 7px 0;
        label {
            width: 100%;
            display: block;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 2px;
        }
        input[type="text"] {
            width: 100%;
            border: 2px solid $light-gray;
            border-radius: 8px;
            padding: 7px 10px;
            margin: 5px 0 0;
        }
        .group-radio {
            padding: 10px 0 0;
            display: flex;
            input[type="radio"] {
                -webkit-appearance: none;
                border: 2px solid $light-gray;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 10px;
            }
            input[type="radio"]:checked {
                border: 2px solid $light-gray;
                background: $gray;
            }
            label {
                letter-spacing: 0;
                font-weight: 500;
            }
        }
        select {
            border-radius: 8px;
            padding: 5px 10px;
            border: 2px solid $light-gray;
            &:focus {
                outline: none;
            }
        }
        &.col-2 {
            display: flex;
            flex-wrap: nowrap;
            label {
                width: 100%;
                display: block;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0px;
            }
        }
    }

    #pagination {
        height: 70px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: $background;
        padding: 10px 0 5px;
        p {
            font-weight: 600;
            color: $dark-gray;
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
            span {
                font-size: 15px;
                margin-left: 8px;
                color: $secundary-color;
            }
        }
        .buttons {
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            .btn-container {
                display: flex;
                justify-content: flex-end;
                button {
                    background: $light-gray;
                    align-items: center;
                    margin: 0 5px;
                    border: none;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    font-weight: 600;
                    &.active {
                        background: $primary-color;
                        color: white;
                    }
                    &.ghost {
                        background: none;
                        &.active {
                            color: $primary-color;
                        }
                    }
                }
                span {
                    padding: 10px 0;
                }
            }
        }
        .qty-select {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            label {
                color: $dark-gray;
                font-size: 14px;
                margin-top: 5px;
                flex: 0 0 80px;
                font-weight: 600;
            }
        }
        .results-qty {
            flex-grow: 1;
        }
    }

    .messages-panel {
        .current-chat-msg {
            .current-chat-wrapper {
                width: 100%;
            }
        }
    }

    .selected-chat {
        background-color: $dark-gray !important;
        p {
            color: white !important;
        }
    }

    #calendar-component .calendar-content-items .item-row .month-day .current-month-day-number {
        font-size: 12px !important;
    }

    #calendar-component .calendar-content-items .item-row .month-day .current-month-day-segments {
        margin-top: 5px;
        margin-left: 15px;
        width: 100%;
    }

    #calendar-component .calendar-content-items .item-row .month-day .current-month-day-segment {
        p {
            display: none;
        }
    }

    .mobile-chat-preview {
        display: flex;
        overflow-x: auto;
        margin: 10px 0;
        .mobile-chat {
            flex-shrink: 0;
            height: 90px;
            width: 100px;
            border-radius: 10px;
            background-color: $light-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: 700;
            text-align: center;
            p{
                font-size: 12px;
            }
            &:not(:last-child) {
                margin-right: 10px;
            }
            .mobile-img {
                width: 35px;
                height: 35px;
                border-radius: 35px;
                margin-bottom: 5px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    #calendar-component {
        margin-top: 10px;
        .calendar-actions {
            justify-content: center;
            .title,
            .export {
                display: none;
            }

            .picker {
                width: 100%;
                display: flex;
                justify-content: center;

                &-calendar-filter {
                    display: flex;
                    visibility: hidden;
                }
            }
        }
        .calendar-content {
            display: none;

            .header-year-mobile {
                display: block;
            }
            .header-year {
                display: none;
            }

            &.display-month {
                display: unset !important;
                .month-day {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .calendar-content-mobile {
            display: block;
            &-header {
                display: flex;
                justify-content: space-around;
                .mobile-week-day {
                    p {
                        text-align: center;
                        font-size: 13px;
                        font-weight: 700;
                        color: $gray;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 5px;
                        &:first-child {
                            color: black;
                        }
                    }
                    .selected-day {
                        background-color: $primary-color;
                        color: white;
                        border-radius: 30px;
                    }

                    .week-day-wrapper {
                        display: flex;
                        justify-content: space-evenly;
                        .week-day-circle {
                            height: 5px;
                            width: 5px;
                            border-radius: 5px;
                        }
                    }
                }
            }
            &-segments {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .mobile-segment {
                    display: flex;
                    margin-bottom: 10px;
                    background-color: #e6e6e6;
                    padding: 20px;
                    border-radius: 8px;
                    font-size: 13px;
                    font-weight: 700;
                    width: calc(50% - 20px);
                    margin: 0 10px 10px;
                    justify-content: space-evenly;
                    &-info {
                        padding: 10px 5px;
                        background-color: $green;
                        margin-right: 10px;
                        border-radius: 8px;
                        text-align: center;
                        height: fit-content;
                        width: 20%;
                        p {
                            color: white;
                        }
                        &.green {
                            background-color: $green;
                        }

                        &.blue {
                            background-color: $secundary-color;
                        }

                        &.red {
                            background-color: $red;
                        }

                        &.empty {
                            background-color: $gray;
                        }
                    }
                    &-items {
                        width: 80%;
                        .mobile-item {
                            background-color: white;
                            margin-bottom: 5px;
                            padding: 8px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            button.chat{
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: 100%;
                                padding: 8px;
                                width: 40px;
                                border: none;
                                border-radius: 10px;
                                background: $gray;
                                svg{
                                    fill: $white;
                                }
                            }
                            .shift-extra-data {
                                position: absolute;
                                top: 5px;
                                right: 10px;
                                font-weight: 700;
                            }
                            .shift-info {
                                text-align: center;
                                padding: 5px;
                                font-weight: 600;
                                .shift {
                                    display: flex;
                                    align-items: center;
                                    .info {
                                        &-name {
                                            font-size: 12px;
                                            font-weight: 700;
                                        }
                                    }
                                    .shift-img {
                                        width: 30px;
                                        margin-right: 10px;
                                        height: 30px;
                                        img {
                                            width: 100%;
                                            border-radius: 30px;
                                        }
                                    }
                                }
                            }

                            .lunch-info {
                                text-align: center;
                                .lunch-time {
                                    color: $primary-color;
                                }
                                .lunch-title {
                                    font-size: 14px;
                                    margin-bottom: 5px;
                                }
                                .lunch-group,
                                .lunch-description {
                                    font-size: 11px;
                                    font-weight: 600;
                                }
                            }

                            .child-info {
                                width: calc(100% - 45px);
                                p{
                                    text-align: left;
                                }
                            }
                            .info {
                                text-align: center;
                                .extra-data {
                                    font-size: 10px;
                                }
                            }
                            .img {
                                width: 35px;
                                height: 35px;
                                margin-right: 10px;
                                img {
                                    width: 100%;
                                }
                            }

                            .activity-info {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                p {
                                    text-align: center;
                                }

                                .info {
                                    margin-top: 10px;
                                }
                                .info,
                                .location {
                                    margin-bottom: 10px;
                                }
                                .location {
                                    font-weight: 600;
                                    font-size: 11px;
                                }
                                .info {
                                    .item-title {
                                        text-align: center;
                                        font-weight: bold;
                                        font-size: 14px;
                                    }
                                    .item-time {
                                        font-weight: 700;
                                        letter-spacing: 4px;
                                        color: #d79ef0;
                                    }
                                }

                                .item-teacher-info {
                                    display: flex;
                                    align-items: center;
                                    font-weight: 600;
                                    font-size: 11px;
                                    &-img {
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 30px;
                                        overflow: hidden;
                                        margin-right: 5px;
                                        img {
                                            object-fit: cover;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .mobile-item-center {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
    .messages-panel {
        height: 60vh;
    }
}

@media (max-width: 500px) {
    #calendar-component .calendar-content-mobile-segments .mobile-segment {
        width: 100%;
        margin: 0 0 10px 0;
    }
}
