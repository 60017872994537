#login{
    background: $white;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    .container{
        width: 310px;
        margin: auto;
        img{
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.spinner {
    width: 70px;
    height: 70px;
    margin: auto;
    background-color: $primary-color;
  
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0); }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }