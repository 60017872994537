#categories-container{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 110px;
    height: calc(100vh - 100px);
    background: $white;
    border-top-right-radius: 100px;
    padding: 80px 21px;
    z-index: 99;
    a{
        display: block;
        padding: 8px;
        margin-bottom: 15px;
        border-radius: 8px;
        text-decoration: none;
        position: relative;
        font-size: 11px;
        font-weight: 600;
        color: $gray;
        text-align: center;
        svg{
            fill: $gray;
            padding: 10px;
        }
        &.selected{
            
            color: $primary-color;
            svg{
                fill: $primary-color;
            }
        }
        .name{
            display: none;
            position: absolute;
            left: 50px;
            top: 5px;
            width: 100px;
            font-size: 12px;
            font-weight: 600;
            background: $white;
            padding: 8px;
            border-radius: 8px;
            text-align: center;
            color: $gray;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
                transition-duration: 300ms;
            &::after{
                content: '';
                width: 0; 
                height: 0; 
                border-right: 10px solid $white;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                position: absolute;
                left: -10px;
                top: 8px;
            }
        }
        &:hover{
            .name{
                display: block;
            }
        }
    }
    button.hamburguer{
        display: none;
    }
}

  

header{
    display: flex;
    height: 90px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    padding: 0 25px;
    background: $background-header;
    z-index: 100;
    #logo{
        width: 130px;
        .large{
            display: block;
        }
        .small{
            display: none;
            height: 50px;
        }
    }
    #name{
        display: none;
    }
    #search-box{
        width: 300px;
        height: 45px;
        padding-left: 20px;
        position: relative;
        .container{
            position: relative;
            width: 280px;
            height: 45px;
        }
        input{
            width: 100%;
            border: none;
            height: 100%;
            padding: 0 15px;
            border-radius: 8px;
            color: $gray;
            font-weight: 600;
            &:focus{
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                + .search-container{
                    display: block;
                }
            }
            &::placeholder{
                color: $gray;
                font-weight: 600;
            }
        }
        button{
            width: 35px;
            height: 35px;
            border-radius: 8px;
            background: $primary-color;
            border: none;
            position: absolute;
            top: 5px;
            right: 5px;
            i{
                color:$white;
            }
        }
        .search-container{
            display: none;
            background: $white;
            position: absolute;
            top: 0;
            width: 280px;
            z-index: -1;
            border-radius: 8px;
            padding: 60px 10px 10px;
            align-items: center;
            -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            .result{
                display: flex;
                padding: 8px 10px;
                border-bottom: 1px solid $light-gray;
                transition-duration: 250ms;
                border-radius: 8px;
                cursor: pointer;
                .img-circle{
                    width: 40px;
                    margin-right: 10px;
                }
                .text{
                    h3{
                        font-size: 13px;
                    }
                    span{
                        font-size: 10px;
                        font-weight: 500;
                    }
                }
                &:last-child{
                    border-bottom: none;
                }
                &:hover{
                    background: $light-gray;
                }
            }
        }
    }
    #subcategories{
        flex-grow: 4;
        .a-container{
            display: flex;
            justify-content: center;
        }
        a{
            display: block;
            padding: 8px 10px;
            border-radius: 8px;
            margin: 0 5px;
            text-decoration: none;
            font-weight: 600;
            font-size: 13px;
            text-align: center;
            &.selected{
                background: $secundary-color;
                color: $white;
            }
        }
    }
    #my-account{
        display: flex;
        align-items: center;
        position: relative;
        button.search{
            display: none;
        }
        .img-circle{
            width: 50px;
        }
        i{
            font-size: 25px;
        }
        .my-account-container{
            display: none;
            background: $white;
            position: absolute;
            top: 60px;
            right: 0;
            width: 190px;
            z-index: -1;
            border-radius: 8px;
            padding: 10px 10px;
            align-items: center;
            -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            &.active{
                display: block;
            }
            button, .dark-mode{
                display: flex;
                padding: 10px 10px;
                transition-duration: 250ms;
                border:none;
                background: none;
                width: 100%;
                border-bottom: 1px solid $light-gray;
                text-align: left;
                justify-content: left;
                font-size: 12px;
                font-weight: 500;
                i{
                    color: $dark-gray;
                    margin-right: 5px; 
                }
            }
            a{
                border-bottom: 1px solid $light-gray;
                
            }
            button:last-child{
                border-bottom: none;
            }
            .dark-mode{
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 60px;
                    height: 34px;
                    input{
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $gray;
                        -webkit-transition: .3s;
                        transition: .3s;
                        border-radius: 34px;
                        &:before{
                            position: absolute;
                            content: "";
                            height: 26px;
                            width: 26px;
                            left: 4px;
                            bottom: 4px;
                            background-color: $white;
                            -webkit-transition: .3s;
                            transition: .3s;
                            border-radius: 50%;
                        }
                        
                    }
                    input:checked + .slider {
                        background-color: $primary-color;
                      }
                      
                      input:focus + .slider {
                        box-shadow: 0 0 1px $primary-color;
                      }
                      
                      input:checked + .slider:before {
                        -webkit-transform: translateX(18px);
                        -ms-transform: translateX(18px);
                        transform: translateX(18px);
                      }
                }
            }
        }
    }
}

#menu-aside{
    display: none;
}

@media (max-height: 700px) {
    #categories-container{
            a{
                flex: 10 0 auto;
                width: 38px!important;
                height: 38px!important;
                margin-bottom: 10px!important;
            }
    }
}

@media (max-width: 900px) {
    #categories-container{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 95px;
        background: $white;
        border-top-right-radius: 0;
        padding: 8px 10px;
        z-index: 99;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
        .a-container{
        display: flex;
        justify-content: left;
        overflow: auto;
        flex-flow: row;
        position: absolute;
        top: 0;
        bottom: 0;
        width: calc(100% - 20px);
        &::-webkit-scrollbar {
            display: none;
          }
            a{
                display: flex;
                flex-direction: column;
                flex: 10 0 auto;
                width: 45px;
                margin: 12px;
                font-size: 13px;
                svg{
                    padding: 0px;
                }
                .relleno{
                    color: $white;
                    width: 40px;
                    height: 40px;
                    display: block;
                    opacity: 0;
                }
                &:hover{
                    .name{
                        display: none;
                    }
                }
                &:last-child{
                    margin-right: 20px;
                }
            }
            button.hamburguer{
                width: 45px;
                height: 45px;
                margin: 10px;
                background: none;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                    color: $gray;
                    font-size: 35px;
                    padding: 5px;
                    padding-right: 15px;
                    border-right: 2px solid $light-gray;
                }
            }
        }
    }
    header{
        display: flex;
        height: auto;
        width: 100%;
        position: relative;
        align-items: center;
        padding: 25px 10px 10px;
        background: $background-header;
        z-index: 100;
        flex-wrap: wrap;
        #logo{
            flex-basis: 50%;
            padding-left: 15px;
            .large{
                display: none;
            }
            .small{
                display: block;
                height: 50px;
            }
        }
        #name{
            display: block;
            text-align: center;
            position: absolute;
            width: 100%;
            top: 40px;
            left: 0;
        }
        #search-box{
            display: flex;
            width: 100%;
            height: 130px;
            padding-left: 0px;
            position: fixed;
            top: -130px;
            z-index: 101;
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            align-items: center;
            justify-content: center;
            transition-duration: 200ms;
            &.active{
                top: 0;
            }
            .container{
                position: relative;

            }
            input{
                width: 280px;
                border: none;
                height: 45px;
                padding: 0 15px;
                border-radius: 8px;
                color: $gray;
                font-weight: 600;
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                &:focus{
                    
                    + .search-container{
                        display: block;
                    }
                }
                &::placeholder{
                    color: $gray;
                    font-weight: 600;
                }
            }
            button{
                width: 35px;
                height: 35px;
                border-radius: 8px;
                background: $primary-color;
                border: none;
                position: absolute;
                top: 5px;
                right: 5px;
                i{
                    color:$white;
                }
                &#close_search_box{
                    background: none;
                    top: 10px;
                    right: 10px;
                    i{
                        font-size: 40px;
                        color: $gray;
                    }
                }
            }
            .search-container{
                display: none;
                background: $white;
                position: absolute;
                top: 0;
                width: 280px;
                z-index: -1;
                border-radius: 8px;
                padding: 60px 10px 10px;
                align-items: center;
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                -webkit-appearance: none;
                .result{
                    display: flex;
                    padding: 8px 10px;
                    border-bottom: 1px solid $light-gray;
                    transition-duration: 250ms;
                    border-radius: 8px;
                    cursor: pointer;
                    .img-circle{
                        width: 40px;
                        margin-right: 10px;
                    }
                    .text{
                        h3{
                            font-size: 13px;
                        }
                        span{
                            font-size: 10px;
                            font-weight: 500;
                        }
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                    &:hover{
                        background: $light-gray;
                    }
                }
            }
        }
        #subcategories{
            justify-content: center;
            order: 5;
            flex-basis: 100%;
            padding: 30px 0 0;
            flex-wrap: nowrap;
            align-items: center;
            position: relative;
            height: 70px;
            .a-container{
                display: flex;
                justify-content: left;
                overflow: auto;
                flex-flow: row;
                position: absolute;
                top: 20px;
                bottom: 0;
                width: calc(100%);
                display: flex;
                align-items: flex-start;
                z-index: 1;
                a{
                    flex: 10 0 auto;
                }
                &::-webkit-scrollbar {
                    display: none;
                  }
            }
            &.overflow{
                justify-content: left;
                max-width: 100%;
                overflow-x: auto;
            }
            
        }
        #my-account{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            flex-basis: 50%;
            button.search{
                display: block;
                border: none;
                background: none;
                color: $gray;
                font-size: 30px;
                i{
                font-size: 30px;
                    color: $gray;
                }
            }
            .img-circle{
                width: 50px;
            }
            & > i{
                font-size: 25px;
                display: none;
            }
            .my-account-container{
                display: none;
                background: $white;
                position: absolute;
                top: 60px;
                right: 0;
                width: 190px;
                z-index: -1;
                border-radius: 8px;
                padding: 10px 10px;
                align-items: center;
                -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
                z-index: 100;
                &.active{
                    display: block;
                }
                button, .dark-mode{
                    display: flex;
                    padding: 10px 10px;
                    transition-duration: 250ms;
                    border:none;
                    background: none;
                    width: 100%;
                    border-bottom: 1px solid $light-gray;
                    text-align: left;
                    justify-content: left;
                    font-size: 12px;
                    font-weight: 500;
                    i{
                        color: $dark-gray;
                        margin-right: 5px; 
                    }
                }
                button:last-child{
                    border-bottom: none;
                }
                .dark-mode{
                    .switch {
                        position: relative;
                        display: inline-block;
                        width: 60px;
                        height: 34px;
                        input{
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }
                        .slider {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: $gray;
                            -webkit-transition: .3s;
                            transition: .3s;
                            border-radius: 34px;
                            &:before{
                                position: absolute;
                                content: "";
                                height: 26px;
                                width: 26px;
                                left: 4px;
                                bottom: 4px;
                                background-color: $white;
                                -webkit-transition: .3s;
                                transition: .3s;
                                border-radius: 50%;
                            }
                            
                        }
                        input:checked + .slider {
                            background-color: $primary-color;
                          }
                          
                          input:focus + .slider {
                            box-shadow: 0 0 1px $primary-color;
                          }
                          
                          input:checked + .slider:before {
                            -webkit-transform: translateX(18px);
                            -ms-transform: translateX(18px);
                            transform: translateX(18px);
                          }
                    }
                }
            }
        }
    }
    #menu-aside{
        display: block;
        width: 250px;
        height: 100%;
        position: fixed;
        padding: 50px 20px;
        top: 0;
        left: -250px;
        z-index: 101;
        background: $white;
        overflow: auto;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
        transition-duration: 250ms;
        &.opened{
            left: 0;
        }
        .img-circle{
            margin: auto;
            width: 150px;
        }
        h2{
            width: 100%;
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
        }
        .categories{
            margin-top: 30px;
            display: flex;
            flex-flow: column wrap;
            svg{
                display: none;
            }
            a{
                .name{
                    display: inline-block;
                    padding: 5px 8px;
                    border-radius: 7px;
                    color: $gray;
                    font-size: 13px;
                    font-weight: 600;
                    margin: 3px 0;
                }
                &.selected{
                    .name{
                        background: $secundary-color;
                        color: $white;
                    }
                }
            }
            .subtitle{
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 2px;
                margin-bottom: 10px;
            }
            
        }
        button{
            
            border-radius: 8px;
            background: $primary-color;
            border: none;
            position: absolute;
            display: block;
            text-align: center;
            color: $white;
            padding: 8px 15px;
                    border-radius: 7px;
                    font-size: 13px;
                    font-weight: 600;
            i{
                color:$white;
            }
            &#close_search_box{
                background: none;
                top: 10px;
                right: 0px;
                i{
                    font-size: 40px;
                    color: $gray;
                }
            }
            &.log_out{
                position: relative;
                display: block;
                margin: 40px auto 0;

            }
        }
    }
}

.searcher{
        width: 100%;
        position: relative;
        z-index: 99;
        input{
            width: 100%;
            border: none;
            padding: 10px 15px;
            border-radius: 8px;
            color: $gray;
            font-weight: 600;
            border: 2px solid $light-gray;
            &:focus{
                + .search-container{
                    display: block;
                }
            }
            &::placeholder{
                color: $gray;
                font-weight: 600;
            }
        }
        button{
            width: 35px;
            height: 35px;
            border-radius: 8px;
            background: $primary-color;
            border: none;
            position: absolute;
            top: 5px;
            right: 5px;
            i{
                color:$white;
            }
        }
        .search-container{
            display: none;
            background: $white;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
            border-radius: 8px;
            padding: 60px 10px 10px;
            align-items: center;
            -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
            .result{
                display: flex;
                padding: 8px 10px;
                border-bottom: 1px solid $light-gray;
                transition-duration: 250ms;
                border-radius: 8px;
                cursor: pointer;
                .img-circle{
                    width: 40px;
                    margin-right: 10px;
                }
                .text{
                    h5{
                        font-size: 13px;
                    }
                    span{
                        font-size: 10px;
                        font-weight: 500;
                    }
                }
                &:last-child{
                    border-bottom: none;
                }
                &:hover{
                    background: $light-gray;
                }
            }
        }
}